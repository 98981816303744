<template>
  <CContainer class="d-flex justify-content-center page404">
    <b-button
      class="text-nowrap"
      variant="secondary"
      @click="viewPDF(idOrder, isAdmin)"
      >明細書</b-button
    >
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="version == 'sp' ? true : false"
      :preview-modal="version == 'sp' ? false : true"
      :paginate-elements-by-height="1200"
      :filename="contentTitle"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      @beforeDownload="beforeDownload($event)"
      ref="DownloadComp"
    >
      <section slot="pdf-content">
        <CRow>
          <CCol md="12">
            <div class="box-content p-3 mt-5">
              <div class="d-flex justify-content-center p-3">
                <div
                  class="d-flex justify-content-center logo"
                  style="width: 25%"
                  :style="{ backgroundImage: 'url(' + logoShop + ')' }"
                ></div>
                <div style="width: 50%"></div>
                <div class="pl-5 pr-0" style="width: 25%">
                  <h6>発行日 : {{ currentDay }}</h6>
                  <h6>注文日 : {{ created_at }}</h6>
                  <h6>{{ distributor_name }}</h6>
                  <h6 v-if="number_resgiter">
                    登録番号: {{ number_resgiter }}
                  </h6>
                </div>
              </div>
              <div class="d-flex justify-content-center py-4">
                <h1>明細書</h1>
              </div>
              <div
                class="d-flex justify-content-end mb-3"
                style="border-bottom: 2px solid"
              >
                <h1>様</h1>
              </div>
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="71%">品番・品名</th>
                    <th width="9%%">数量</th>
                    <th>金額</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ product }}</td>
                    <td class="text-center">{{ quantity }}</td>
                    <td class="text-right">
                      ¥{{ Math.round(Number(price)).toLocaleString("ja") }}
                    </td>
                  </tr>
                  <tr>
                    <td>配送料・手数料</td>
                    <td></td>
                    <td class="text-right">
                      ¥{{ Math.round(Number(ship)).toLocaleString("ja") }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "合計" }}</td>
                    <td></td>
                    <td class="text-right">
                      ¥{{ Math.round(Number(amount)).toLocaleString("ja") }}
                    </td>
                  </tr>
                  <tr v-if="tax > 0">
                    <td>{{ "うち" + tax + "%対象" }}</td>
                    <td></td>
                    <td class="text-right">
                      ¥{{
                        Math.round(Number(amount)).toLocaleString("ja")
                      }}
                      （消費税: ¥{{
                        Math.round(Number(amount * tax * 0.01)).toLocaleString(
                          "ja"
                        )
                      }}）
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCol>
        </CRow>
      </section>
    </vue-html2pdf>
  </CContainer>
</template>

<script>
import { Constants } from "../../utils/constants";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import no_image from "@/assets/img/logo_default.png";
import VueHtml2pdf from "vue-html2pdf";
// import html2pdf from "html2pdf.js";
moment.locale("ja");
export default {
  name: "PageInvoice",
  props: ["idOrder", "contentTitle", "version", "isAdmin", "isSubscription"],
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      dataShop: [],
      page_404: null,
      homepage: window.location.origin,
      shop_id: this.$route.params.shopId,
      htmlText: "",
      item: "",
      top_page: "",
      inquiry_page: "",
      id: "",
      title: "",
      content: "",
      quantity: 1,
      tax: 0,
      price: 0,
      tax_price: 0,
      ship: 0,
      amount: 0,
      initialCost: 0,
      product: "",
      created_at: "",
      currentDay: "",
      logoShop: "",
      showTemplate: false,
      distributor_name: "",
      number_resgiter: "",
    };
  },
  async created() {
    this.currentDay = moment(new Date()).format("LL");
  },
  computed: {
    ...mapGetters(["orderByIdInUser", "ListSettingShop", "orderById"]),
  },
  methods: {
    ...mapActions({
      getOrderByIdInUser: "getOrderByIdInUser",
      getOrderById: "getOrderById",
      getListSettingShopUser: "getListSettingShopUser",
      getListSettingShopAdmin: "getListSettingShopAdmin",
    }),
    async viewPDF(id, isAdmin) {
      if (isAdmin) {
        const resSetting = await this.$store.dispatch(
          "getListSettingShopAdmin",
          {
            domain: Constants.DOMAIN,
            shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
          }
        );

        const req = {
          id: id,
          shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
        };
        const resOrder = await this.$store.dispatch("getOrderById", req);
        await this.handleData(resSetting, resOrder);
      } else {
        const resSetting = await this.$store.dispatch(
          "getListSettingShopUser",
          {
            domain: Constants.DOMAIN,
            shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
          }
        );
        const req = {
          id: id,
          shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
        };
        const resOrder = await this.$store.dispatch("getOrderByIdInUser", req);

        await this.handleData(resSetting, resOrder);
      }

      var element = this.$refs.DownloadComp;

      element.generatePdf();
    },

    handleData(resSetting, resOrder) {
      this.dataShop = resSetting.data;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }

      this.product = resOrder.data.title;
      this.created_at = moment(new Date(resOrder.data.created_at)).format("LL");
      this.distributor_name = resOrder.data.author.distributor_name;
      this.number_resgiter = resOrder.data.author.number_resgiter;
      let sale_info = resOrder.data.sale_info;

      if (sale_info && sale_info[0]) {
        if (this.isSubscription) {
          this.initialCost = sale_info[0].initialCost;
        }
        this.price = sale_info[0].product_price ?? 0;
        if (
          parseInt(sale_info[0].priceOverTime) > 0 &&
          sale_info[0].fromTimeRelease <=
            moment(String(new Date())).format("YYYY-MM-DD HH:mm") &&
          sale_info[0].toTimeRelease >=
            moment(String(new Date())).format("YYYY-MM-DD HH:mm")
        ) {
          this.price = parseInt(sale_info[0].priceOverTime);
        }
        this.tax = sale_info[0].tax ? sale_info[0].tax : 0;
        this.ship = sale_info[0].fee ? sale_info[0].fee : 0;
        this.amount = this.price * 1 + this.ship * 1 + this.initialCost * 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.link-home-page:hover {
  color: #aaa !important;
}
.default-layout .page404 .box-content {
  width: 1000px;
  margin: 10px auto;
  box-shadow: 0px 3px 6px #7681929c !important;
  border-radius: 20px !important;
}
@media screen and (max-width: 991px) {
  .default-layout .page404 .box-content {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .container {
    width: 100% !important;
    font-size: 16px !important;
  }
}
@media (min-width: 991px) {
  .container {
    width: 100% !important;
  }
}

.table-bordered th,
.table-bordered td {
  border-color: white !important;
}

.table-bordered thead th {
  border-top-width: 20px;
  border-top-color: black !important;
  text-align: center;
}

.table {
  border-bottom: 2px solid black !important;
}
.logo {
  width: 240px !important;
  height: 60px !important;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
