<template>
  <div class="table-subscription-pc">
    <Tables
      :items="purchaseHistorySubscription.data"
      :fields="fieldsSubscription"
      :itemsPerPageSelect="{}"
    >
      <template v-slot:id="{ index }">
        <td>{{ index + 1 }}</td>
      </template>
      <template v-slot:created_at="{ item }">
        <td>
          <div v-if="item.created_at !== null">
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </div>
        </td>
      </template>
      <template v-slot:action="{ item }">
        <td class="d-flex justify-content-around">
          <div
            v-if="item.status_order != 3"
            @click="showModalConfirmCancel(item)"
            class="btn-cancel"
          >
            解約手続き
          </div>
          <div v-else>解約済み</div>
          <div>
            <Download
              :idOrder="item.order_id"
              :contentTitle="item.content_title"
              :isSubscription="true"
            />
          </div>
        </td>
      </template>
    </Tables>
  </div>
</template>

<script>
import { tableFields } from "../../utils/table-fields.js";
import Tables from "../../components/common/Tables";
import { mapActions } from "vuex";
import moment from "moment";
import Download from "../pages/pagePrintInvoice";
import { eventBus } from "../../main";

export default {
  name: "MyProfile",
  components: {
    Tables,
    Download,
  },
  props: ["purchaseHistorySubscription"],
  data() {
    return {
      fieldsSubscription: tableFields.PURCHASE_USER_SUBSCRIPTION,
    };
  },
  created() {},
  methods: {
    ...mapActions({
      cancelSubscriptionUser: "cancelSubscriptionUser",
    }),
    moment,
    showModalConfirmCancel(value) {
      eventBus.$emit("showModalConfirmCancel", value);
    },
  },
};
</script>
<style>
.table-subscription-pc .position-relative {
  position: unset !important;
}
</style>
