<template>
  <div class="table-payment-pc">
    <Tables
      :items="purchaseHistoryOneTime.data"
      :fields="fieldsOneTime"
      :itemsPerPageSelect="{}"
    >
      <template v-slot:id="{ index }">
        <td>{{ index + 1 }}</td>
      </template>
      <template v-slot:created_at="{ item }">
        <td>
          <div v-if="item.created_at !== null">
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </div>
        </td>
      </template>
      <template v-slot:action="{ item }">
        <td>
          <Download
            :idOrder="item.order_id"
            :contentTitle="item.content_title"
          />
        </td>
      </template>
    </Tables>
  </div>
</template>

<script>
import { tableFields } from "../../utils/table-fields.js";
import Tables from "../../components/common/Tables";
import { mapActions } from "vuex";
import moment from "moment";
import { Constants } from "../../utils/constants.js";
import Download from "../pages/pagePrintInvoice";

export default {
  name: "MyProfile",
  components: {
    Tables,
    Download,
  },
  props: ["purchaseHistoryOneTime"],
  data() {
    return {
      fieldsOneTime: tableFields.PURCHASE_USER_ONE_TIME,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {},
  methods: {
    ...mapActions({
      cancelSubscriptionUser: "cancelSubscriptionUser",
    }),
    moment,
    goContentDetail(content_id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "DetailContentLibrary"
          : "DetailContentLibrary domain",
        params: {
          idContent: content_id,
          shopId: this.shop_id,
        },
      });
    },
  },
};
</script>
<style>
.table-payment-pc .position-relative {
  position: unset !important;
}
</style>
