<template>
  <div class="card-content page-myprofile">
    <div class="dc-header mb-4">
      <b-container fluid class="d-flex align-items-center font-title">
        <router-link
          :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
        >
          <div>ショップ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link :to="{ name: '' }">
          <div>マイページ</div>
        </router-link>
      </b-container>
    </div>

    <div class="version-pc">
      <div
        class="background-user d-flex justify-content-center"
        :style="{ background: colorPicker }"
        style="height: 200px"
      >
        <div class="detail-background-user">
          <div class="w-100 text-right position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-gear-fill icon-setting-pc"
              viewBox="0 0 16 16"
              @click="togglePicker()"
            >
              <path
                d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
              />
            </svg>
            <chrome-picker
              v-if="displayPicker"
              v-model="colors"
              class="color"
              @input="updateFromPicker"
            />
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="avatar-pc">
                <span v-if="!infoUser.avatar" class="title m-auto">写真</span>
                <img
                  v-if="infoUser.avatar && isLoadImg"
                  :src="infoUser.avatar"
                  alt=""
                />
                <img
                  v-if="infoUser.avatar && !isLoadImg"
                  :src="`${urlBackend}/${infoUser.avatar}`"
                  alt=""
                />
                <input type="file" @change="uploadAvatar" />
              </div>
              <h5 class="my-auto ml-3" v-if="infoUser">
                {{
                  infoUser.lastName && infoUser.firstName
                    ? infoUser.lastName + infoUser.firstName
                    : ""
                }}
              </h5>
            </div>
            <div class="statistical-order">
              <h1 class="text-center" style="font-size: 3rem">
                {{ dataLibrary.total }}
              </h1>
              <h5>所持コンテンツ</h5>
            </div>
          </div>
        </div>
      </div>
      <VueSlickCarousel
        v-bind="settingsSlickPc"
        ref="carousel"
        class="car-slide"
      >
        <div class="content-profile">
          <div class="body-profile position-relative">
            <div class="px-5 user-profile text-left">
              <div class="d-flex">
                <h6 class="w-75">プロフィール</h6>
                <div
                  class="w-25 text-md-right text-sm-right text-left"
                  :disabled="isLoadingLogout"
                  v-on:click="!isLoadingLogout ? logoutBtn() : ''"
                >
                  <span
                    class="my-auto"
                    :style="!isLoadingLogout ? { cursor: 'pointer' } : {}"
                    >ログアウト
                  </span>
                  <b-icon
                    icon="box-arrow-right"
                    font-scale="1.5"
                    class="icon-menu"
                    :style="!isLoadingLogout ? { cursor: 'pointer' } : {}"
                  />
                </div>
              </div>
              <CRow>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>ニックネーム</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput class="input-profile" v-model="infoUser.nickName" />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>姓</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput
                      v-model="infoUser.lastName"
                      :isValid="infoUser.lastNameRequired"
                      :invalidFeedback="infoUser.messLastNameRequired"
                      class="input-profile"
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>名</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput
                      v-model="infoUser.firstName"
                      :isValid="infoUser.firstNameRequired"
                      :invalidFeedback="infoUser.messFirstNameRequired"
                      class="input-profile"
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>生年月日</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <div class="custom-input-group">
                      <div
                        role="group"
                        class="cus-mg input-profile form-group"
                        style="width: 65px"
                      >
                        <CInput
                          v-model="infoUser.yearBirthDay"
                          onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                          maxLength="4"
                          placeholder="YYYY"
                          :isValid="infoUser.yearInvalid"
                          :invalidFeedback="infoUser.messYearInvalid"
                          class="input-profile"
                        />
                      </div>
                      <span class="cus-mg">年</span>
                      <div role="group" class="cus-mg input-profile form-group">
                        <CInput
                          v-model="infoUser.monthBirthDay"
                          onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                          maxLength="2"
                          placeholder="MM"
                          :isValid="infoUser.monthInvalid"
                          :invalidFeedback="infoUser.messMonthInvalid"
                          @blur="formatMonthAndDate"
                          class="input-profile"
                        />
                      </div>
                      <span class="cus-mg">月</span>
                      <div role="group" class="cus-mg input-profile form-group">
                        <CInput
                          v-model="infoUser.dayBirthDay"
                          onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                          maxLength="2"
                          placeholder="DD"
                          :isValid="infoUser.dateInvalid"
                          :invalidFeedback="infoUser.messDateInvalid"
                          @blur="formatMonthAndDate"
                          class="input-profile"
                        />
                      </div>
                      <span class="cus-mg">日</span>
                    </div>
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>生時</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <div class="fd-admin-listproperty-card-filter filter-date">
                      <date-picker
                        name="date"
                        v-model="infoUser.hourBirthDay"
                        :config="optionsDateHour"
                        autocomplete="off"
                        style="margin-bottom: 1rem"
                        placeholder="出生時間を選択"
                        class="input-profile"
                      ></date-picker>
                    </div>
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>性別</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CSelect
                      v-model="infoUser.sex"
                      :options="optionsGender"
                      class="input-profile"
                    ></CSelect>
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>職業</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CSelect
                      v-model="infoUser.occupation"
                      :options="listOccupation"
                      class="input-profile"
                    ></CSelect>
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>郵便番号</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <div class="custom-input-group">
                      <span class="mr-3">〒</span>
                      <CInput
                        class="mr-3 input-profile"
                        v-model="infoUser.zipCodeOne"
                        v-on:blur="
                          getAddress(
                            `${infoUser.zipCodeOne}${infoUser.zipCodeTwo}`
                          )
                        "
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        maxLength="3"
                        :isValid="infoUser.zipCodeInvalid"
                        :invalidFeedback="infoUser.messZipCodeInvalid"
                      />
                      <span class="mr-3">-</span>
                      <CInput
                        v-model="infoUser.zipCodeTwo"
                        v-on:blur="
                          getAddress(
                            `${infoUser.zipCodeOne}${infoUser.zipCodeTwo}`
                          )
                        "
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        maxLength="4"
                        :isValid="infoUser.zipCodeInvalid"
                        :invalidFeedback="infoUser.messZipCodeInvalid"
                        class="input-profile"
                      />
                    </div>
                  </CCol>
                </div>
                <CCol sm="12"></CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt"></CCol>
                  <CCol class="cus-inp">
                    <CInput
                      v-model="infoUser.address_area"
                      class="input-profile"
                      disabled
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>住所</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput v-model="infoUser.address" class="input-profile" />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>建物名・部屋番号</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput
                      v-model="infoUser.building_room"
                      class="input-profile"
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>電話番号</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <div class="custom-input-group">
                      <CInput
                        class="mr-3 input-profile"
                        v-model="infoUser.phone1"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        maxLength="6"
                        :isValid="infoUser.phoneInvalid1"
                        :invalidFeedback="infoUser.messPhoneInvalid1"
                      />
                      <span class="mr-3">-</span>
                      <CInput
                        class="mr-3 input-profile"
                        v-model="infoUser.phone2"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        maxLength="4"
                        :isValid="infoUser.phoneInvalid2"
                        :invalidFeedback="infoUser.messPhoneInvalid2"
                      />
                      <span class="mr-3">-</span>
                      <CInput
                        v-model="infoUser.phone3"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                        maxLength="4"
                        :isValid="infoUser.phoneInvalid3"
                        :invalidFeedback="infoUser.messPhoneInvalid3"
                        class="input-profile"
                      />
                    </div>
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>メールアドレス</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput
                      autocomplete="off"
                      v-model="infoUser.email"
                      :isValid="infoUser.emailRequired || infoUser.emailInvalid"
                      :invalidFeedback="
                        infoUser.emailRequired === false
                          ? infoUser.messEmailRequired
                          : infoUser.emailInvalid === false
                          ? infoUser.messEmailInvalid
                          : ''
                      "
                      class="input-profile"
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>パスワード</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput
                      autocomplete="new-password"
                      v-model="infoUser.password"
                      type="password"
                      :isValid="
                        infoUser.passwordRequired === false
                          ? infoUser.passwordRequired
                          : infoUser.passwordLength === false
                          ? infoUser.passwordLength
                          : infoUser.passwordInvalid === false
                          ? infoUser.passwordInvalid
                          : null
                      "
                      :invalidFeedback="
                        infoUser.messPasswordRequired ||
                        infoUser.messPasswordLength ||
                        infoUser.messPasswordInvalid
                      "
                      class="input-profile"
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <div class="d-flex w-100">
                  <CCol class="cus-tt">
                    <label>パスワード再入力</label>
                  </CCol>
                  <CCol class="cus-inp">
                    <CInput
                      v-model="infoUser.confirmPassword"
                      type="password"
                      :isValid="infoUser.passwordNotMatch"
                      :invalidFeedback="infoUser.messPasswordNotMatch"
                      class="input-profile"
                    />
                  </CCol>
                </div>
                <CCol sm="12">
                  <hr />
                </CCol>
                <CCol sm="12" style="text-align: center">
                  <CButton
                    :size="'sm'"
                    class="mt-3 btn-success"
                    @click="updateBasicInfo()"
                    :disabled="infoUser.loading"
                  >
                    <b-spinner v-if="infoUser.loading" small></b-spinner>
                    この入力内容に変更する
                  </CButton>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>

        <div class="content-profile" v-if="detailMyProfileSettingUser">
          <div class="position-relative">
            <PageMyProfile
              :detailMyProfileSettingUser="detailMyProfileSettingUser"
            />
          </div>
        </div>

        <div class="content-profile">
          <div class="body-profile position-relative">
            <div class="px-5 user-profile text-left">
              <!-- <h6 class="pl-3 pb-3" style="font-weight: bold">
                ご契約中の定期購読
              </h6> -->
              <h6 class="w-75">定期購読中コンテンツ</h6>
              <TableSubPc
                :purchaseHistorySubscription="purchaseHistorySubscription"
              >
              </TableSubPc>
              <div
                class="d-flex justify-content-center position-relative"
                v-if="purchaseHistorySubscription.total"
              >
                <p class="font-weight-bold">
                  {{ purchaseHistorySubscription.total }}件中{{
                    purchaseHistorySubscription.data.length
                  }}件を表示
                </p>
              </div>
              <div
                class="d-flex justify-content-center position-relative"
                v-if="purchaseHistorySubscription.total"
              >
                <CPagination
                  v-if="purchaseHistorySubscription.total"
                  :activePage.sync="pageSubscription"
                  :pages="purchaseHistorySubscription.last_page"
                  align="center"
                />
                <div class="custom-selectLimitPage">
                  <b-form-select
                    v-model="limitSubscription"
                    tag-placeholder="Add this as new category"
                    :options="listLimitRecordPageSubscription"
                    @change="changeLimitPageSubscription()"
                  ></b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-profile">
          <div class="body-profile position-relative">
            <div class="px-5 user-profile text-left">
              <h6 class="w-75">購入・所持済みコンテンツ</h6>
              <TableHistoryPc :purchaseHistoryOneTime="purchaseHistoryOneTime">
              </TableHistoryPc>
              <div
                class="d-flex justify-content-center position-relative"
                v-if="purchaseHistoryOneTime.total"
              >
                <p class="font-weight-bold">
                  {{ purchaseHistoryOneTime.total }}件中{{
                    purchaseHistoryOneTime.data.length
                  }}件を表示
                </p>
              </div>
              <div
                class="d-flex justify-content-center position-relative"
                v-if="purchaseHistoryOneTime.last_page"
              >
                <CPagination
                  v-if="purchaseHistoryOneTime.total"
                  :activePage.sync="pageOneTime"
                  :pages="purchaseHistoryOneTime.last_page"
                  align="center"
                />
                <div class="custom-selectLimitPage">
                  <b-form-select
                    v-if="purchaseHistoryOneTime.total"
                    v-model="limitOneTime"
                    tag-placeholder="Add this as new category"
                    :options="listLimitRecordPageOneTime"
                    @change="changeLimitPageOneTime()"
                  ></b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-profile">
          <div class="body-profile position-relative">
            <div class="px-5 user-profile text-left">
              <div v-if="deleteAccount">
                <div class="pt-4 pc-profile-section">
                  <h6 class="pl-3 pb-3" style="font-weight: bold">退会</h6>
                  <div class="author-detail mt-3">
                    <div class="d-flex justify-content-center">
                      <div class="mb-3">
                        <p class="text-center">退会の注意点</p>
                        <p class="text-center">
                          ご契約中の定期購読がある場合、退会に進めません。
                        </p>
                        <p class="text-center">
                          退会する前にすべての定期購読を解約する必要がございます。
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center" v-if="checkSub">
                      <b-button
                        v-b-modal.delete-modal-acc
                        variant="dark"
                        class="m-auto px-5"
                        name="profile_withdrawal_confirm_button"
                        >退会する</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- modal confirm cancel -->
              <div>
                <b-modal id="confirm-cancel" hide-header hide-footer>
                  <div class="d-block text-center mb-5 position-relative">
                    <h5>解約の注意点</h5>
                    <button
                      type="button"
                      aria-label="Close"
                      class="close"
                      @click="$bvModal.hide('confirm-cancel')"
                      style="position: absolute; left: 0; top: 0"
                    >
                      ×
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="mb-3">
                      <div>
                        <span style="font-size: 18px">①</span> 「{{
                          contentSubscription.content_title
                        }}」のコンテンツが<span
                          style="text-decoration: underline"
                          >すべて見れなくなります</span
                        >。
                      </div>
                      <div>
                        <span style="font-size: 18px">②</span>
                        <span style="text-decoration: underline"
                          >付属したすべての特典</span
                        >が見れなくなります。
                      </div>
                      <div>
                        <span style="font-size: 18px">③</span>
                        再度申し込む時、価格が違うことがあります。その場合は<span
                          style="text-decoration: underline"
                          >最新の価格での申し込み</span
                        >となりますのでご注意ください。
                      </div>
                      <div class="text-center mt-4 mb-3">
                        本当によろしいですか？
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b-button
                      class="btn back-btn"
                      style="
                        margin-right: 40px;
                        background: #00a2ff;
                        color: #fff;
                        min-width: 110px;
                        padding: 12px 0;
                        border-radius: 0;
                        border: 0;
                      "
                      @click="$bvModal.hide('confirm-cancel')"
                    >
                      <span>戻る</span>
                    </b-button>
                    <div
                      @click="
                        cancelSubscriptionPayment(
                          contentSubscription.author_id,
                          contentSubscription.order_id,
                          contentSubscription.payment_type
                        )
                      "
                      class="text-center btn-cancel-text"
                    >
                      <b-spinner v-if="isLoadingCancel" small></b-spinner>
                      このまま解約する（確認画面なし）
                    </div>
                  </div>
                </b-modal>
              </div>
              <div>
                <b-modal id="delete-modal-acc" hide-header hide-footer>
                  <div class="d-block text-center mb-5">
                    <h5>退会の注意点</h5>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="mb-3">
                      <p class="text-center">本当に退会しますか？</p>
                      <p class="text-center">
                        購入したコンテンツが全て削除されます。復元は一切できません
                      </p>
                      <p class="text-center">のでご注意ください。</p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="w-50 justify-content-center d-flex">
                      <b-button
                        class="mx-auto btn btn-danger w-75 back-btn"
                        @click="$bvModal.hide('delete-modal-acc')"
                        name="profile_not_withdrawal_button"
                        :disabled="isLoadDelAcc"
                        >戻る</b-button
                      >
                    </div>
                    <div class="justify-content-center w-50 d-flex my-auto">
                      <b-button
                        class="mx-auto btn w-75"
                        block
                        @click="deleteAcc()"
                        name="profile_withdrawal_button"
                        :disabled="isLoadDelAcc"
                      >
                        <b-spinner v-if="isLoadDelAcc" small></b-spinner>
                        <span>このまま退会する</span>
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>

    <!-- version sp -->
    <div class="version-sp">
      <div
        class="background-user position-relative"
        :style="{ background: colorPicker }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-gear-fill icon-setting"
          viewBox="0 0 16 16"
          @click="$bvModal.show('create-color')"
        >
          <path
            d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
          />
        </svg>
        <div class="avatar">
          <span v-if="!infoUser.avatar" class="title">写真</span>
          <img
            v-if="infoUser.avatar && isLoadImg"
            :src="infoUser.avatar"
            alt=""
          />
          <img
            v-if="infoUser.avatar && !isLoadImg"
            :src="`${urlBackend}/${infoUser.avatar}`"
            alt=""
          />
          <input type="file" @change="uploadAvatar" />
        </div>
      </div>
      <VueSlickCarousel v-bind="settingsSlick">
        <!-- <div class="content-profile">
          <div class="body-profile position-relative">
            <h6 class="body-title">利用可能ポイント</h6>
            <div
              class="number-point"
              v-if="infoUser.point || infoUser.point === 0"
            >
              {{ Number(infoUser.point).toLocaleString("ja") }}
            </div>
            <h6 class="unit-point">ポイント</h6>
          </div>
          <div class="d-flex position-relative justify-content-center">
            <CButton class="btn-success" v-on:click="showPurchasePointModal()"
              >Buy Points</CButton
            >
          </div>
        </div> -->
        <div class="content-profile">
          <div class="body-profile position-relative">
            <div class="d-flex">
              <h6 class="body-title w-50">基本情報</h6>
              <div
                class="w-50 text-right text-left pt-3 pr-3"
                :disabled="isLoadingLogout"
                v-on:click="!isLoadingLogout ? logoutBtn() : ''"
              >
                <span
                  class="my-auto"
                  :style="!isLoadingLogout ? { cursor: 'pointer' } : {}"
                  >ログアウト
                </span>
                <b-icon
                  icon="box-arrow-right"
                  font-scale="1.5"
                  class="icon-menu"
                  :style="!isLoadingLogout ? { cursor: 'pointer' } : {}"
                />
              </div>
            </div>
            <div class="user-profile">
              <CInput
                v-model="infoUser.lastName"
                :isValid="infoUser.lastNameRequired"
                :invalidFeedback="infoUser.messLastNameRequired"
                placeholder="姓"
              />
              <CInput
                v-model="infoUser.firstName"
                :isValid="infoUser.firstNameRequired"
                :invalidFeedback="infoUser.messFirstNameRequired"
                placeholder="名"
              />
              <CInput
                autocomplete="off"
                v-model="infoUser.email"
                :isValid="infoUser.emailRequired || infoUser.emailInvalid"
                :invalidFeedback="
                  infoUser.emailRequired === false
                    ? infoUser.messEmailRequired
                    : infoUser.emailInvalid === false
                    ? infoUser.messEmailInvalid
                    : ''
                "
                placeholder="メールアドレス"
              />
              <CInput
                autocomplete="new-password"
                v-model="infoUser.password"
                type="password"
                :isValid="
                  infoUser.passwordRequired === false
                    ? infoUser.passwordRequired
                    : infoUser.passwordLength === false
                    ? infoUser.passwordLength
                    : infoUser.passwordInvalid === false
                    ? infoUser.passwordInvalid
                    : null
                "
                :invalidFeedback="
                  infoUser.messPasswordRequired ||
                  infoUser.messPasswordLength ||
                  infoUser.messPasswordInvalid
                "
                placeholder="パスワード"
              />
              <div v-if="!isShowMore" class="text-center">
                <CButton
                  :size="'sm'"
                  class="btn-info"
                  @click="isShowMore = true"
                >
                  すべて表示
                </CButton>
              </div>
              <div v-if="isShowMore">
                <CInput
                  v-model="infoUser.confirmPassword"
                  type="password"
                  :isValid="infoUser.passwordNotMatch"
                  :invalidFeedback="infoUser.messPasswordNotMatch"
                  placeholder="パスワード再入力"
                />
                <CInput
                  v-model="infoUser.nickName"
                  placeholder="ニックネーム"
                />
                <div class="d-flex">
                  <CInput
                    class="mr-2"
                    v-model="infoUser.yearBirthDay"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="4"
                    placeholder="YYYY"
                    :isValid="infoUser.yearInvalid"
                    :invalidFeedback="infoUser.messYearInvalid"
                  />
                  <CInput
                    class="mr-2"
                    v-model="infoUser.monthBirthDay"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="2"
                    placeholder="MM"
                    :isValid="infoUser.monthInvalid"
                    :invalidFeedback="infoUser.messMonthInvalid"
                    @blur="formatMonthAndDate"
                  />
                  <CInput
                    v-model="infoUser.dayBirthDay"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="2"
                    placeholder="DD"
                    :isValid="infoUser.dateInvalid"
                    :invalidFeedback="infoUser.messDateInvalid"
                    @blur="formatMonthAndDate"
                  />
                </div>
                <date-picker
                  name="date"
                  v-model="infoUser.hourBirthDay"
                  :config="optionsDateHour"
                  autocomplete="off"
                  style="margin-bottom: 1rem"
                  placeholder="出生時間を選択"
                ></date-picker>
                <CSelect
                  class="mt-3"
                  v-model="infoUser.sex"
                  :options="optionsGender"
                ></CSelect>
                <CSelect
                  v-model="infoUser.occupation"
                  :options="listOccupation"
                ></CSelect>
                <div class="d-flex mb-3">
                  <span class="mr-3 position-relative align-self-center"
                    >〒</span
                  >
                  <CInput
                    class="mr-3 w-25 mb-0"
                    v-model="infoUser.zipCodeOne"
                    v-on:blur="
                      getAddress(`${infoUser.zipCodeOne}${infoUser.zipCodeTwo}`)
                    "
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="3"
                    :isValid="infoUser.zipCodeInvalid"
                    :invalidFeedback="infoUser.messZipCodeInvalid"
                    placeholder="XXX"
                  />
                  <span class="mr-3 align-self-center">-</span>
                  <CInput
                    class="w-25 mb-0"
                    v-model="infoUser.zipCodeTwo"
                    v-on:blur="
                      getAddress(`${infoUser.zipCodeOne}${infoUser.zipCodeTwo}`)
                    "
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="4"
                    :isValid="infoUser.zipCodeInvalid"
                    :invalidFeedback="infoUser.messZipCodeInvalid"
                    placeholder="XXXX"
                  />
                </div>
                <CInput v-model="infoUser.address" placeholder="住所" />
                <div class="d-flex mb-3">
                  <CInput
                    class="mr-3 mb-0"
                    v-model="infoUser.phone1"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="3"
                    :isValid="infoUser.phoneInvalid1"
                    :invalidFeedback="infoUser.messPhoneInvalid1"
                    placeholder="XXX"
                  />
                  <span class="mr-3 align-self-center">-</span>
                  <CInput
                    class="mr-3 mb-0"
                    v-model="infoUser.phone2"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="4"
                    :isValid="infoUser.phoneInvalid2"
                    :invalidFeedback="infoUser.messPhoneInvalid2"
                    placeholder="XXXX"
                  />
                  <span class="mr-3 align-self-center">-</span>
                  <CInput
                    class="mb-0"
                    v-model="infoUser.phone3"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    maxLength="4"
                    :isValid="infoUser.phoneInvalid3"
                    :invalidFeedback="infoUser.messPhoneInvalid3"
                    placeholder="XXXX"
                  />
                </div>
                <CInput
                  v-model="infoUser.linkFacebook"
                  placeholder="Facebook"
                />
                <CButton
                  v-if="infoUser.linkFacebook"
                  :size="'sm'"
                  class="btn-info"
                  @click="routeSNS(infoUser.linkFacebook)"
                >
                  設定
                </CButton>
                <CInput v-model="infoUser.linkTwitter" placeholder="Twitter" />
                <CButton
                  v-if="infoUser.linkTwitter"
                  :size="'sm'"
                  class="btn-info"
                  @click="routeSNS(infoUser.linkTwitter)"
                >
                  設定
                </CButton>
                <CInput v-model="infoUser.linkLine" placeholder="Line" />
                <CButton
                  :size="'sm'"
                  class="btn-info"
                  @click="routeSNS(infoUser.linkLine)"
                  v-if="infoUser.linkLine"
                >
                  設定
                </CButton>
                <CInput v-model="infoUser.linkAmazon" placeholder="Amazon" />
                <CButton
                  v-if="infoUser.linkAmazon"
                  :size="'sm'"
                  class="btn-info"
                  @click="routeSNS(infoUser.linkAmazon)"
                >
                  設定
                </CButton>
                <div class="text-center">
                  <CButton
                    :size="'sm'"
                    class="mt-3 btn-success mb-3"
                    @click="updateBasicInfo()"
                    :disabled="infoUser.loading"
                  >
                    <b-spinner v-if="infoUser.loading" small></b-spinner>
                    この入力内容に変更する
                  </CButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-profile" v-if="detailMyProfileSettingUser">
          <div class="position-relative">
            <PageMyProfile
              :detailMyProfileSettingUser="detailMyProfileSettingUser"
            />
          </div>
        </div>

        <div class="content-profile">
          <div class="body-profile position-relative">
            <h6 class="body-title">購入履歴</h6>
            <TableHistory :listPurchaseHistory="listPurchaseHistory">
            </TableHistory>
            <div v-if="!isShowAllHistory" class="btn-show-all">
              <button
                class="btn btn-secondary"
                @click="showAllHistoryPayment()"
              >
                すべて表示
              </button>
            </div>
          </div>
        </div>
        <div class="content-profile">
          <div class="body-profile position-relative">
            <h6 class="body-title">月額支払い中</h6>
            <TableSub
              :listPurchaseHistorySubscriptionSP="
                listPurchaseHistorySubscriptionSP
              "
            >
            </TableSub>
            <div v-if="!isShowAll" class="btn-show-all">
              <button class="btn btn-secondary" @click="showAllSubscription()">
                すべて表示
              </button>
            </div>
          </div>
        </div>
        <div class="content-profile">
          <div class="body-profile position-relative cus-delete-act">
            <h6 class="body-title">退会</h6>
            <div class="author-detail mt-3">
              <div class="container d-flex justify-content-center">
                <div class="mb-3">
                  <p class="text-center">退会の注意点</p>
                  <p class="text-center">
                    ご契約中の定期購読がある場合、退会に進めません。
                  </p>
                  <p class="text-center">
                    退会する前にすべての定期購読を解約する必要がございます。
                  </p>
                </div>
              </div>
              <div
                class="container d-flex justify-content-center"
                v-if="checkSub"
              >
                <b-button
                  v-b-modal.delete-modal-acc
                  variant="dark"
                  class="m-auto px-5"
                  name="profile_withdrawal_confirm_button"
                  >退会する</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <!--<div class="content-profile">
          <div class="body-profile position-relative">
            <h6 class="body-title">ヒアリング</h6>
            <div class="survey">
              <label>質問1</label>
              <div class="d-flex">
                <CInput />
                <button class="btn btn-secondary">更新</button>
              </div>
              <label>質問2</label>
              <div class="d-flex">
                <CInput />
                <button class="btn btn-secondary">更新</button>
              </div>
            </div>
            <div class="btn-show-all">
              <button class="btn btn-secondary">すべて表示</button>
            </div>
          </div>
        </div>-->
        <!--<div class="content-profile">
          <div class="body-profile position-relative">
            <h6 class="body-title">過去回答したアンケート</h6>
            <div class="survey">
              <label>質問1</label>
              <div class="d-flex">
                <CInput />
                <button class="btn btn-secondary">更新</button>
              </div>
              <label>質問2</label>
              <div class="d-flex">
                <CInput />
                <button class="btn btn-secondary">更新</button>
              </div>
            </div>
            <div class="btn-show-all">
              <button class="btn btn-secondary">すべて表示</button>
            </div>
          </div>
        </div>-->
      </VueSlickCarousel>
    </div>
    <!-- modal create color -->
    <div>
      <b-modal id="create-color" hide-header hide-footer>
        <div class="p-4">
          <CRow class="mb-3">
            <CCol class="col-4">
              <label>背景画像</label>
            </CCol>
            <CCol class="col-8">
              <b-button class="btn-info btn-upload-image">
                <input type="file" @change="uploadAvatar" />
                アップロードする
              </b-button>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="col-4">
              <label>背景色</label>
            </CCol>
            <CCol class="col-8">
              <div
                class="btn-upload-color"
                :style="{ background: colorPicker }"
                @click="$bvModal.show('show-color')"
              />
            </CCol>
          </CRow>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="show-color" hide-footer>
        <template #modal-header>
          <h5
            id="same-content___BV_modal_title_"
            class="modal-title"
            style="position: absolute; left: 50%; transform: translateX(-50%)"
          >
            色
          </h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('show-color')"
          >
            ×
          </button>
        </template>
        <chrome-picker v-model="colors" class="color" />
        <div class="btn-submit-color">
          <b-button class="btn-success" @click="saveColor">更新する</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
// import { tableFields } from "../../utils/table-fields.js";
// import Tables from "../../components/common/Tables";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { FeedBack } from "@/utils/feedback.js";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { Urls } from "../../utils/urls";
import { Api } from "../../utils/http-common.js";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import TableSub from "../default/TableSubscription.vue";
import TableSubPc from "../default/TableSubscriptionPc.vue";
import TableHistory from "../default/TableHistoryPayment.vue";
import TableHistoryPc from "../default/TableHistoryPaymentPc.vue";
import PageMyProfile from "../default/PageMyProfile.vue";
// import Download from "../pages/pagePrintInvoice";
// import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { eventBus } from "../../main";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "MyProfile",
  components: {
    // Tables,
    VueSlickCarousel,
    TableSub,
    TableSubPc,
    TableHistory,
    TableHistoryPc,
    // StripeElementCard,
    // Download,
    PageMyProfile,
  },
  data() {
    return {
      urlBackend: Constants.URL_BE,
      checkSub: true,
      shopId: this.$route.params.shopId,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      info: localStorage.getItem(Constants.USER_TYPE_USER)
        ? JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO))
        : JSON.parse(localStorage.getItem(Constants.ADMIN_USER_INFO)),
      infoUser: {
        userType: localStorage.getItem(Constants.USER_TYPE_USER),
        id: "",
        nickName: "",
        firstName: "",
        firstNameRequired: null,
        messFirstNameRequired: "",
        lastName: "",
        lastNameRequired: null,
        messLastNameRequired: "",
        sex: "",
        birthDay: "",
        dayBirthDay: "",
        monthBirthDay: "",
        yearBirthDay: "",
        yearInvalid: null,
        monthInvalid: null,
        dateInvalid: null,
        messYearInvalid: "",
        messMonthInvalid: "",
        messDateInvalid: "",
        hourBirthDay: "",
        zipCodeOne: "",
        zipCodeTwo: "",
        zipCodeInvalid: null,
        messZipCodeInvalid: "",
        building_room: "",
        address_area: "",
        address: "",
        phone1: "",
        phone2: "",
        phone3: "",
        phoneInvalid1: null,
        messPhoneInvalid1: "",
        phoneInvalid2: null,
        messPhoneInvalid2: "",
        phoneInvalid3: null,
        messPhoneInvalid3: "",
        loading: false,
        prefecture: "",
        city: "",
        area: "",
        email: "",
        emailRequired: null,
        messEmailRequired: "",
        emailInvalid: null,
        messEmailInvalid: "",
        loadingEmail: false,
        password: "",
        confirmPassword: "",
        passwordRequired: null,
        messPasswordRequired: "",
        passwordLength: null,
        messPasswordLength: "",
        passwordInvalid: null,
        messPasswordInvalid: "",
        passwordNotMatch: null,
        messPasswordNotMatch: "",
        loadingPassword: false,
        point: 0,
        loadingPayment: false,
        occupation: "経営者・役員",
        linkFacebook: "",
        linkTwitter: "",
        linkLine: "",
        linkAmazon: "",
        avatar: "",
      },
      optionsGender: ["男性", "女性", "未回答"],
      optionsDate: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        showClear: false,
        showClose: false,
        // minDate: new Date() + "",
        locale: "ja",
      },
      optionsDateHour: {
        format: "HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      listLimitRecordPageSubscription: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limitSubscription: 10,
      pageSubscription: 1,
      listLimitRecordPageOneTime: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limitOneTime: 10,
      pageOneTime: 1,
      listOccupation: Constants.OCCUPATION,
      urlRedirect: "",
      deleteAccount: null,
      month31: ["01", "03", "05", "07", "08", "10", "12"],
      settingsSlick: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        swipeToSlide: true,
      },
      settingsSlickPc: {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        centerMode: true,
        variableWidth: true,
        swipeToSlide: true,
      },
      isShowMore: false,
      colors: "e19c9b",
      colorPicker: "",
      isLoadImg: false,
      listPurchaseHistorySubscriptionSP: [],
      listPurchaseHistoryOnetimeSP: [],
      listPurchaseHistory: [],
      isShowAll: false,
      isShowAllHistory: false,
      isUrl: Constants.REGEX_URL,
      amount: 0,
      show: true,
      isLoading: true,
      publishableKey: null,
      token: null,
      isLoadDelAcc: false,
      contentSubscription: {
        author_id: "",
        order_id: "",
        payment_type: "",
        content_title: "",
      },
      isLoadingCancel: false,
      isLoadingLogout: false,
      displayPicker: false,
    };
  },
  mounted() {
    const carouselEl = document.querySelector(".car-slide");
    carouselEl.addEventListener("wheel", (e) => {
      if (e.deltaX) e.preventDefault();
      if (e.deltaX > 25) {
        this.$refs.carousel.next();
      } else if (e.deltaX < -25) {
        this.$refs.carousel.prev();
      }
    });
    eventBus.$on("showModalConfirmCancel", (value) => {
      this.showModalConfirmCancel(value);
    });
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "userProfile",
      "purchaseHistorySubscription",
      "purchaseHistoryOneTime",
      "pointOfUser",
      "listOrderSub",
      "ListSettingShop",
      "purchaseHistoryAll",
      "PurchasePointById",
      "detailMyProfileSettingUser",
      "dataLibrary",
    ]),
    points() {
      if (this.isLoading) {
        return "Wait";
      }
      return this.calculatePoints(this.amount);
    },
    point_bonus_settings() {
      return this.PurchasePointById;
    },
  },
  async created() {
    // const { params } = this.$route;
    // const { shopId } = params;
    this.getDataLibrary({ shop_id: this.shop_id });
    this.showMyProfileSettingUser({ shop_id: this.shop_id });
    const formData = {
      id: this.infoUser.id,
      shop_id: this.shop_id,
      user_type: localStorage.getItem(Constants.USER_TYPE_USER),
    };
    this.infoUser.id = this.info.user_id;
    this.getProfile(formData);
    this.getListPurchaseSubscription(1);
    this.getListPurchaseOneTime(1);
    this.getUserUsablePoint(this.shop_id);
    const req = {
      shop_id: this.shop_id,
      type: Constants.SUBCRIPTION,
    };
    this.getListOrderSubcription(req);
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    });
    const reg = {
      user_type: this.infoUser.userType,
      data: {
        user_id: this.infoUser.id,
        shop_id: this.shop_id,
        type: "other",
        limit: 3,
      },
    };
    this.getAllPurchaseHistory(reg);
    const shop_info =
      localStorage.getItem(Constants.DETAIL_SHOP) &&
      JSON.parse(localStorage.getItem(Constants.DETAIL_SHOP));
    this.publishableKey = shop_info.stripe_id;
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.infoUser.loading = false;
        this.infoUser.loadingEmail = false;
        this.infoUser.loadingPassword = false;
        this.isLoadingCancel = false;
        this.$bvModal.hide("confirm-cancel");
        this.getListPurchaseSubscription(this.pageSubscription);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.infoUser.loading = false;
        this.infoUser.loadingEmail = false;
        this.infoUser.loadingPassword = false;
        this.isLoadingCancel = false;
      }
    },
    "infoUser.firstName"() {
      if (this.infoUser.firstName) {
        this.infoUser.firstNameRequired = null;
        this.infoUser.messFirstNameRequired = "";
      }
    },
    "infoUser.lastName"() {
      if (this.infoUser.lastName) {
        this.infoUser.lastNameRequired = null;
        this.infoUser.messLastNameRequired = "";
      }
    },
    "infoUser.zipCodeOne"() {
      this.infoUser.zipCodeInvalid = null;
      this.infoUser.messZipCodeInvalid = "";
    },
    "infoUser.zipCodeTwo"() {
      this.infoUser.zipCodeInvalid = null;
      this.infoUser.messZipCodeInvalid = "";
    },
    "infoUser.email"() {
      this.infoUser.emailRequired = null;
      this.infoUser.emailInvalid = null;
      this.infoUser.messEmailRequired = "";
      this.infoUser.messEmailInvalid = "";
    },
    "infoUser.yearBirthday"() {
      this.infoUser.yearInvalid = null;
      this.infoUser.messYearInvalid = "";
    },
    "infoUser.monthBirthday"() {
      this.infoUser.monthInvalid = null;
      this.infoUser.messMonthInvalid = "";
    },
    "infoUser.dayBirthday"() {
      this.infoUser.dateInvalid = null;
      this.infoUser.messDateInvalid = "";
    },
    "infoUser.password"() {
      this.infoUser.passwordRequired = null;
      this.infoUser.messPasswordRequired = "";
      this.infoUser.passwordLength = null;
      this.infoUser.messPasswordLength = "";
      this.infoUser.passwordInvalid = null;
      this.infoUser.messPasswordInvalid = "";
    },
    "infoUser.confirmPassword"() {
      this.infoUser.passwordNotMatch = null;
      this.infoUser.messPasswordNotMatch = "";
    },
    "infoUser.phone1"() {
      this.infoUser.phoneInvalid1 = null;
      this.infoUser.messPhoneInvalid1 = "";
    },
    "infoUser.phone2"() {
      this.infoUser.phoneInvalid2 = null;
      this.infoUser.messPhoneInvalid2 = "";
    },
    "infoUser.phone3"() {
      this.infoUser.phoneInvalid3 = null;
      this.infoUser.messPhoneInvalid3 = "";
    },
    userProfile() {
      if (Object.keys(this.userProfile).length) {
        const {
          nick_name,
          first_name,
          last_name,
          email,
          birthday,
          time_birth,
          sex,
          occupation,
          postal_code,
          address,
          province,
          municipalities,
          city,
          building_room,
          tel,
          link_facebook,
          link_twitter,
          link_line,
          link_amazon,
          background_color,
          avatar,
        } = this.userProfile;
        this.infoUser.nickName = nick_name;
        this.infoUser.firstName = first_name;
        this.infoUser.lastName = last_name;
        this.infoUser.email = email;
        this.infoUser.yearBirthDay = birthday ? birthday.split("-")[0] : "";
        this.infoUser.monthBirthDay = birthday ? birthday.split("-")[1] : "";
        this.infoUser.dayBirthDay = birthday ? birthday.split("-")[2] : "";
        this.infoUser.hourBirthDay = time_birth;
        this.infoUser.sex = this.optionsGender[sex];
        this.infoUser.occupation = occupation ? occupation : "経営者・役員";
        this.infoUser.zipCodeOne = postal_code
          ? postal_code.substring(0, 3)
          : "";
        this.infoUser.zipCodeTwo = postal_code
          ? postal_code.substring(3, 7)
          : "";
        this.getAddress(postal_code);
        if (province || city || municipalities) {
          this.infoUser.address_area = province + city + municipalities;
        } else {
          this.infoUser.address_area = "";
        }
        this.infoUser.address = address;
        this.infoUser.building_room = building_room;
        this.infoUser.phone1 = tel ? tel.split("-")[0] : "";
        this.infoUser.phone2 = tel ? tel.split("-")[1] : "";
        this.infoUser.phone3 = tel ? tel.split("-")[2] : "";
        this.infoUser.linkFacebook = link_facebook;
        this.infoUser.linkTwitter = link_twitter;
        this.infoUser.linkLine = link_line;
        this.infoUser.linkAmazon = link_amazon;
        this.colorPicker = background_color ? background_color : "e19c9b";
        this.colors = background_color ? background_color : "e19c9b";
        this.infoUser.avatar = avatar;
      }
    },
    pointOfUser() {
      this.infoUser.point = this.pointOfUser;
    },
    purchaseHistorySubscription() {
      if (
        this.purchaseHistorySubscription.data &&
        this.purchaseHistorySubscription.data.length > 0
      ) {
        if (!this.isShowAll) {
          for (let index = 0; index < 3; index++) {
            this.listPurchaseHistorySubscriptionSP.push(
              this.purchaseHistorySubscription.data[index]
            );
          }
        } else {
          this.listPurchaseHistorySubscriptionSP =
            this.purchaseHistorySubscription.data;
        }
        // change title content
        let baseDefault = this.$root.$refs.baseDefault;
        this.purchaseHistorySubscription.data.forEach((item) => {
          item.content_title = baseDefault.changeSentence(item.content_title);
          if (item.status_order != 3) {
            this.checkSub = false;
            return false;
          }
        });
      }
      this.pageSubscription = this.purchaseHistorySubscription.current_page;
    },
    purchaseHistoryAll() {
      if (this.purchaseHistoryAll.length > 0) {
        this.listPurchaseHistory = this.purchaseHistoryAll;
      }
    },
    purchaseHistoryOneTime() {
      // change title content
      let baseDefault = this.$root.$refs.baseDefault;
      this.purchaseHistoryOneTime.data.forEach(function (item) {
        item.content_title = baseDefault.changeSentence(item.content_title);
      });
      this.pageOneTime = this.purchaseHistoryOneTime.current_page;
    },
    pageSubscription() {
      this.getListPurchaseSubscription(this.pageSubscription);
    },
    pageOneTime() {
      this.getListPurchaseOneTime(this.pageOneTime);
    },
    ListSettingShop() {
      if (this.ListSettingShop && this.ListSettingShop.length > 0) {
        this.urlRedirect = this.ListSettingShop[0].url_delete_account;
        this.deleteAccount = this.ListSettingShop[0].delete_allow_account;
      }
    },
    PurchasePointById() {
      this.isLoading = false;
      this.point_bonus_settings.step_3_column = JSON.parse(
        this.PurchasePointById.step_3_column
      );
    },
  },
  methods: {
    ...mapActions({
      getProfile: "getProfile",
      getListOrderSubcription: "getListOrderSubcription",
      getListSettingShopUser: "getListSettingShopUser",
      updateProfile: "updateProfile",
      getPurchaseHistorySubscriptionUser: "getPurchaseHistorySubscriptionUser",
      getPurchaseHistoryOneTimeUser: "getPurchaseHistoryOneTimeUser",
      getUserUsablePoint: "getUserUsablePoint",
      cancelSubscriptionUser: "cancelSubscriptionUser",
      getAllPurchaseHistory: "getAllPurchaseHistory",
      getPurchasePointById: "getPurchasePointById",
      checkStripeKey: "checkStripeKey",
      showMyProfileSettingUser: "showMyProfileSettingUser",
      deleteRealtimeActivity: "deleteRealtimeActivity",
      getDataLibrary: "getDataLibrary",
    }),
    handleScrollLeft(event) {
      console.log("stop");
      event.preventDefault(); // Ngăn chặn sự kiện mặc định của trình duyệt
      // Thực hiện các hành động khác khi cuộn bên trái
    },
    async updateUser(error, action_type) {
      if (!error) {
        const {
          nickName,
          firstName,
          lastName,
          dayBirthDay,
          monthBirthDay,
          yearBirthDay,
          hourBirthDay,
          zipCodeOne,
          zipCodeTwo,
          prefecture,
          area,
          city,
          address,
          building_room,
          phone1,
          phone2,
          phone3,
          email,
          password,
          sex,
          occupation,
          linkFacebook,
          linkLine,
          linkTwitter,
          linkAmazon,
        } = this.infoUser;
        const formCreateUser = {
          user_type: localStorage.getItem(Constants.USER_TYPE_USER),
          id: this.infoUser.id,
          shop_id: this.shop_id,
          nick_name:
            action_type === "basic" ? nickName : this.userProfile.nick_name,
          first_name:
            action_type === "basic" ? firstName : this.userProfile.first_name,
          last_name:
            action_type === "basic" ? lastName : this.userProfile.last_name,
          email: action_type === "basic" ? email : this.userProfile.email,
          birthday:
            action_type === "basic" &&
            yearBirthDay &&
            monthBirthDay &&
            dayBirthDay
              ? yearBirthDay + "-" + monthBirthDay + "-" + dayBirthDay
              : this.userProfile.birthDay,
          time_birth:
            action_type === "basic" ? hourBirthDay : this.userProfile.hours,
          password: action_type === "basic" ? password : "",
          sex:
            action_type === "basic" && sex
              ? this.optionsGender.indexOf(sex)
              : this.userProfile.sex,
          occupation:
            action_type === "basic" ? occupation : this.userProfile.occupation,
          postal_code:
            action_type === "basic" && zipCodeOne && zipCodeTwo
              ? zipCodeOne + zipCodeTwo
              : this.userProfile.postal_code,
          address: action_type === "basic" ? address : this.userProfile.address,
          province: prefecture,
          municipalities: area,
          city: city,
          building_room: building_room,
          tel:
            action_type === "basic" && phone1 && phone2 && phone3
              ? phone1 + "-" + phone2 + "-" + phone3
              : this.userProfile.tel,
          link_facebook: linkFacebook,
          link_twitter: linkTwitter,
          link_line: linkLine,
          link_amazon: linkAmazon,
        };
        var dataReturn = await this.$store.dispatch(
          "updateProfile",
          formCreateUser
        );
        if (dataReturn) {
          this.infoUser.loading = false;
        }
      } else {
        this.infoUser.loading = false;
        this.infoUser.loadingEmail = false;
        this.infoUser.loadingPassword = false;

        this.$toasted.error("必須項目のご入力をお願いします。");
      }
    },
    updateBasicInfo() {
      this.infoUser.loading = true;
      let error = false;
      if (!this.infoUser.firstName || this.infoUser.firstName.trim() === "") {
        this.infoUser.firstNameRequired = false;
        this.infoUser.messFirstNameRequired = FeedBack.FIRSTNAMENULL;
        error = true;
      }
      if (!this.infoUser.lastName || this.infoUser.lastName.trim() === "") {
        this.infoUser.lastNameRequired = false;
        this.infoUser.messLastNameRequired = FeedBack.LASTNAMENULL;
        error = true;
      }
      if (
        this.infoUser.phone1 &&
        !this.infoUser.phone1.match(Constants.REGEX_NUMBER)
      ) {
        this.infoUser.phoneInvalid1 = false;
        this.infoUser.messPhoneInvalid1 = FeedBack.INVALID;
        error = true;
      }
      if (
        this.infoUser.phone2 &&
        !this.infoUser.phone2.match(Constants.REGEX_NUMBER)
      ) {
        this.infoUser.phoneInvalid2 = false;
        this.infoUser.messPhoneInvalid2 = FeedBack.INVALID;
        error = true;
      }
      if (
        this.infoUser.phone3 &&
        !this.infoUser.phone3.match(Constants.REGEX_NUMBER)
      ) {
        this.infoUser.phoneInvalid3 = false;
        this.infoUser.messPhoneInvalid3 = FeedBack.INVALID;
        error = true;
      }
      if (!this.isYearValid()) {
        this.infoUser.yearInvalid = false;
        this.infoUser.messYearInvalid = FeedBack.INVALID;
        error = true;
      } else {
        this.infoUser.yearInvalid = true;
      }
      if (!this.isMonthValid()) {
        this.infoUser.monthInvalid = false;
        this.infoUser.messMonthInvalid = FeedBack.INVALID;
        error = true;
      } else {
        this.infoUser.monthInvalid = true;
      }
      if (!this.isDateValid()) {
        this.infoUser.dateInvalid = false;
        this.infoUser.messDateInvalid = FeedBack.INVALID;
        error = true;
      } else {
        this.infoUser.dateInvalid = true;
      }
      if (!this.isZipCodeValid()) {
        this.infoUser.zipCodeInvalid = false;
        this.infoUser.messZipCodeInvalid = FeedBack.INVALID;
        error = true;
      }
      if (this.updateEmailInfo() && this.updatePasswordInfo() && !error) {
        this.updateUser(error, "basic");
      } else {
        this.infoUser.loading = false;
        this.infoUser.loadingEmail = false;
        this.infoUser.loadingPassword = false;

        this.$toasted.error("必須項目のご入力をお願いします。");
      }
    },
    isZipCodeValid() {
      if (
        (this.infoUser.zipCodeTwo || this.infoUser.zipCodeOne) &&
        (!this.infoUser.prefecture ||
          !this.infoUser.city ||
          !this.infoUser.area)
      ) {
        return false;
      } else {
        return true;
      }
    },
    updateEmailInfo() {
      this.infoUser.loadingEmail = true;
      let error = false;
      if (!this.infoUser.email || this.infoUser.email.trim() === "") {
        this.infoUser.emailRequired = false;
        this.infoUser.messEmailRequired = FeedBack.EMAILNULL;
        error = true;
      }
      if (!this.infoUser.email.match(Constants.REGEX_EMAIL)) {
        this.infoUser.emailInvalid = false;
        this.infoUser.messEmailInvalid = FeedBack.EMAIL_INVALID;
        error = true;
      }
      return !error;
    },
    updatePasswordInfo() {
      this.infoUser.loadingPassword = true;
      let error = false;
      if (this.infoUser.password && this.infoUser.password.length < 8) {
        this.infoUser.passwordLength = false;
        this.infoUser.messPasswordLength = FeedBack.PASSWORD_8_CHARACTER;
        error = true;
      }
      if (
        this.infoUser.password &&
        !this.infoUser.password.match(Constants.CHECK_PASSWORD)
      ) {
        this.infoUser.passwordInvalid = false;
        this.infoUser.messPasswordInvalid = FeedBack.ALPHANUMERIC_PASSWORD;
        error = true;
      }
      if (
        this.infoUser.password &&
        (this.infoUser.confirmPassword == "" ||
          this.infoUser.password !== this.infoUser.confirmPassword)
      ) {
        this.infoUser.passwordNotMatch = false;
        this.infoUser.messPasswordNotMatch = FeedBack.PASSWORDINCORRECT;
        error = true;
      }
      return !error;
    },
    getAddress(postcode) {
      if (postcode) {
        var postal_code = require("japan-postal-code");
        const _this = this;
        _this.infoUser.prefecture = "";
        _this.infoUser.city = "";
        _this.infoUser.area = "";
        _this.infoUser.address_area = "";
        postal_code.get(postcode, function (address_area) {
          _this.infoUser.prefecture = address_area.prefecture;
          _this.infoUser.area = address_area.area;
          _this.infoUser.city = address_area.city;
          _this.infoUser.address_area =
            _this.infoUser.prefecture +
            _this.infoUser.city +
            _this.infoUser.area;
        });
      }
    },
    updatePaymentInfo() {
      this.infoUser.loadingPayment = true;
    },
    getListPurchaseSubscription(page) {
      if (page === undefined) {
        page = this.pageSubscription;
      }
      const formData = {
        user_type: this.infoUser.userType,
        page: page,
        data: {
          user_id: this.infoUser.id,
          shop_id: this.shop_id,
          limit: this.limitSubscription,
        },
      };
      this.getPurchaseHistorySubscriptionUser(formData);
    },
    getListPurchaseOneTime(page) {
      if (page === undefined) {
        page = this.pageOneTime;
      }
      const formData = {
        user_type: this.infoUser.userType,
        page: page,
        data: {
          user_id: this.infoUser.id,
          shop_id: this.shop_id,
          limit: this.limitOneTime,
        },
      };
      this.getPurchaseHistoryOneTimeUser(formData);
    },
    changeLimitPageSubscription() {
      this.getListPurchaseSubscription(1);
    },
    changeLimitPageOneTime() {
      this.getListPurchaseOneTime(1);
    },
    async deleteAcc() {
      this.isLoadDelAcc = true;
      const data = {
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteAccount", data);
      if (dataReturn && dataReturn.success) {
        this.isLoadDelAcc = false;
        localStorage.removeItem(Constants.USER_TYPE_USER);
        localStorage.removeItem(Constants.TOKEN_USER);
        localStorage.removeItem(Constants.NORMAL_USER_INFO);
        localStorage.removeItem(Constants.USER_ID);
        localStorage.removeItem(Constants.EXPIRES_AT_USER);
        localStorage.removeItem(Constants.IS_REMEMBER_USER);
        localStorage.removeItem(Constants.LIST_SLAG);
        if (this.urlRedirect) {
          window.location.href = this.urlRedirect;
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "login shop"
              : "login shop domain",
            params: { shopId: this.$route.params.shopId },
          });
        }
        this.isLoadDelAcc = false;
      } else {
        this.isLoadDelAcc = false;
      }
    },
    formatMonthAndDate() {
      if (this.infoUser.monthBirthDay.length === 1) {
        this.infoUser.monthBirthDay = "0" + this.infoUser.monthBirthDay;
      }
      if (this.infoUser.dayBirthDay.length === 1) {
        this.infoUser.dayBirthDay = "0" + this.infoUser.dayBirthDay;
      }
    },
    isLeapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    },
    isDateValid() {
      const inputDay =
        this.infoUser.yearBirthDay +
        "-" +
        this.infoUser.monthBirthDay +
        "-" +
        this.infoUser.dayBirthDay;
      if (
        this.infoUser.dayBirthDay ||
        this.infoUser.monthBirthDay ||
        this.infoUser.yearBirthDay
      ) {
        const dateInFeb = this.isLeapYear(this.infoUser.yearBirthDay) ? 29 : 28;
        const dateInMonth = this.month31.includes(this.infoUser.monthBirthDay)
          ? 31
          : this.infoUser.monthBirthDay === "02"
          ? dateInFeb
          : 30;
        if (
          parseInt(this.infoUser.dayBirthDay) <= dateInMonth &&
          new Date(inputDay) < new Date()
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isYearValid() {
      if (
        this.infoUser.dayBirthDay ||
        this.infoUser.monthBirthDay ||
        this.infoUser.yearBirthDay
      ) {
        if (parseInt(this.infoUser.yearBirthDay) <= new Date().getFullYear()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isMonthValid() {
      if (
        this.infoUser.dayBirthDay ||
        this.infoUser.monthBirthDay ||
        this.infoUser.yearBirthDay
      ) {
        if (parseInt(this.infoUser.monthBirthDay) <= 12) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    async cancelSubscriptionPayment(author_id, order_id, type) {
      if (!this.isLoadingCancel) {
        this.isLoadingCancel = true;
        const reg = {
          author_id: author_id,
          order_id: order_id,
          type: type === 2 ? "stripe" : "paypal",
          shop_id: this.shop_id,
        };
        const dataReturn = await this.$store.dispatch(
          "cancelSubscriptionUser",
          reg
        );
        if (dataReturn) {
          this.isLoadingCancel = false;
          this.getListPurchaseSubscription(this.pageSubscription);
          this.$bvModal.hide("confirm-cancel");
        }
      }
    },
    routeSNS(link) {
      window.open(link, "_blank");
    },
    saveColor() {
      const data = {
        shop_id: this.shop_id,
        background_color: this.colors.hex8,
      };
      const url = `/${Urls.PROFILE}/${Urls.COLOR}`;
      Api.userRequestServer
        .patch(url, data)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.colorPicker = this.colors.hex8;
            this.$bvModal.hide("show-color");
          } else {
            this.$toasted.error(data.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$toasted.error(error.message);
          }
        });
    },
    uploadAvatar(event) {
      var formData = new FormData();
      formData.append("shop_id", this.shop_id);
      formData.append("avatar", event.target.files[0]);
      const url = `/${Urls.PROFILE}/${Urls.AVATAR}`;
      Api.userRequestServer
        .post(url, formData)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.isLoadImg = true;
            this.infoUser.avatar = event.target.files;
            var reader = new FileReader();

            reader.onload = (e) => {
              this.infoUser.avatar = e.target.result;
            };
            reader.readAsDataURL(event.target.files[0]);
            this.$bvModal.hide("create-color");
          } else {
            this.$toasted.error(data.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$toasted.error(error.message);
          }
        });
    },
    showAllSubscription() {
      this.isShowAll = true;
      const reg = {
        user_type: this.infoUser.userType,
        data: {
          user_id: this.infoUser.id,
          shop_id: this.shop_id,
          type: "subscription",
          limit: 0,
        },
      };
      this.getAllPurchaseHistory(reg);
    },
    showAllHistoryPayment() {
      this.isShowAllHistory = true;
      const reg = {
        user_type: this.infoUser.userType,
        data: {
          user_id: this.infoUser.id,
          shop_id: this.shop_id,
          type: "other",
        },
      };
      this.getAllPurchaseHistory(reg);
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.amount = 0;
      // Trick to reset/clear native browser form validation state
      this.$nextTick(() => {
        this.show = true;
      });
    },
    showConfirmPaymentPoint(inputAmount) {
      if (inputAmount == 0) {
        this.$toasted.error(
          "入力ポイントの数は 0 より大きくなければなりません。"
        );
      } else {
        this.$bvModal.show("payment-info");
      }
    },
    showPurchasePointModal() {
      const data = {
        shop_id: parseInt(this.shop_id),
      };
      this.getPurchasePointById(data);
      this.$bvModal.show("purchase-point-modal");
    },
    calculatePoints(inputAmount) {
      let total = inputAmount;
      const {
        step_1_setting,
        step_1_bonus,
        step_1_point,
        step_2_setting,
        step_2_up,
        step_3_setting,
        step_3_column,
      } = this.point_bonus_settings;
      if (step_1_setting) {
        let bonus = Math.floor(inputAmount / step_1_point) * step_1_bonus;
        total = inputAmount + bonus;
        // if (inputAmount >= step_1_point) {
        //   total += step_1_bonus;
        // }
      }
      if (step_2_setting) {
        let add = (inputAmount * step_2_up) / 100;
        total += add;
      }

      if (step_3_setting) {
        let max = 0;
        let a = step_3_column.filter((obj) => obj.step_3_point <= inputAmount);
        max =
          a.length === 0
            ? 0
            : a.reduce((acc, e) =>
                acc.step_3_point > e.step_3_point ? acc : e
              );
        if (max !== 0) {
          let add = (inputAmount * max.step_3_bonus) / 100;
          total += add;
        }
      }
      return total;
    },
    tokenCreated(token) {
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      const url = `/${Urls.STRIPE}/${Urls.POINT}/${Urls.PAYMENT}`;
      const order = {
        token: token.id,
        shop_id: this.shop_id,
        price: this.amount,
        point: this.points,
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.document.activeElement.href
          ? window.document.activeElement.href
          : window.document.activeElement.baseURI,
        id_button: "point_purchase_button",
      };
      api
        .post(url, order)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$bvModal.hide("loading-payment");
            this.$bvModal.hide("payment-info");
          } else {
            this.$bvModal.hide("loading-payment");
            this.$bvModal.hide("payment-info");
            this.$toasted.error(data.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$bvModal.hide("loading-payment");
            this.$bvModal.hide("payment-info");
            this.$toasted.error(
              "決済時にシステムエラーが発生しました。再度お試しください。"
            );
          }
        });
      this.getUserUsablePoint(this.shop_id);
    },
    submit() {
      this.$bvModal.show("loading-payment");
      this.$refs.elementRef.submit();
    },
    showModalConfirmCancel(value) {
      this.contentSubscription.author_id = value.author_id;
      this.contentSubscription.order_id = value.order_id;
      this.contentSubscription.payment_type = value.payment_type;
      this.contentSubscription.content_title = value.content_title;
      this.$bvModal.show("confirm-cancel");
    },
    logoutBtn() {
      this.isLoadingLogout = true;
      const shopId = this.shop_id;
      // this.deleteRealtimeActivity({
      //   user_id: localStorage.getItem(Constants.USER_ID),
      //   shop_id: localStorage.getItem(Constants.SHOP_ID),
      // });
      Api.userRequestServer
        .post(Urls.LOGOUT)
        .then((response) => {
          this.isLoadingLogout = false;
          const { data } = response;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
          }
          localStorage.removeItem(Constants.TOKEN_USER);
          localStorage.removeItem(Constants.USER_TYPE_USER);
          localStorage.removeItem(Constants.EXPIRES_AT_USER);
          localStorage.removeItem(Constants.USER_ID);
          localStorage.removeItem(Constants.EMAIL_USER);
          localStorage.removeItem(Constants.LIST_SLAG);
          localStorage.removeItem(Constants.NORMAL_USER_INFO);
          localStorage.removeItem(Constants.IS_REMEMBER_USER);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login shop",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login shop domain",
            });
          }
        })
        .catch(() => {
          this.isLoadingLogout = false;
          localStorage.removeItem(Constants.TOKEN_USER);
          localStorage.removeItem(Constants.USER_TYPE_USER);
          localStorage.removeItem(Constants.EXPIRES_AT_USER);
          localStorage.removeItem(Constants.USER_ID);
          localStorage.removeItem(Constants.EMAIL_USER);
          localStorage.removeItem(Constants.LIST_SLAG);
          localStorage.removeItem(Constants.NORMAL_USER_INFO);
          localStorage.removeItem(Constants.IS_REMEMBER_USER);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login shop",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login shop domain",
            });
          }
        });
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromPicker(color) {
      this.colors = color;
      this.saveColor();
    },
  },
};
</script>
<style lang="scss">
.cart {
  background-color: #f5f5f5;
  min-height: calc(100vh - 280px);
  .cart-empty {
    width: 100%;
    min-height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      background-color: #fe3834;
      color: #fff;
    }
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .checkout-btn {
    width: 100%;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
}
.checkout-screen {
  background-color: #f5f5f5;
  padding-bottom: 10px;
  .card {
    background: #7a95c6;
    color: white;
    justify-content: center;
    margin: auto;
  }
  #stripe-element-mount-point {
    border: 1px solid blue;
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .custom-button-paypal {
    background: #ffc439;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    &:hover {
      cursor: pointer;
      background: #fcb208;
    }
  }
  .checkout-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #635bff;
  }
  .cancel-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
  .showmore-btn {
    width: auto;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #e19c9b;
  }
  .input-card {
    margin-left: 40px;
    .check-box-card {
      margin: 5px;
      margin-right: 10px;
    }
    .select-card {
      width: 240px;
    }
  }
}
.model-foote {
  margin-top: 10px;
}
.hover:hover {
  cursor: pointer;
}
.fd-admin-listproperty-card-filter {
  position: relative;
  label {
    display: inline;
    border: none;
  }
  button {
    padding: 0;
    padding-bottom: 4px;
    box-shadow: none !important;
  }
  .b-calendar-header {
    display: none;
  }
  .b-calendar-nav {
    border: 1px solid #d8dbe0;
    background-color: #ffffff;
    border-color: #768192 !important;
    border-radius: 0.25rem;
    .btn-outline-secondary {
      color: #6c778a !important;
    }
  }
  .b-calendar-nav:focus {
    border-color: #768192 !important;
    box-shadow: none;
  }
  .b-calendar-grid:focus {
    border-color: #768192 !important;
    box-shadow: none;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
  }
  .b-calendar-inner {
    position: absolute;
    top: 30px;
  }
}
.custom-selectLimitPage {
  position: absolute;
  right: 0;
}
.form-control {
  color: #000;
}
.form-control:focus {
  color: #000;
}
.btn-cancel:hover {
  cursor: pointer;
}
.detail-content {
  margin: 0 20%;
  @media (max-width: 449px) {
    min-height: auto;
    margin: 0;
  }
}
.input-profile {
  background: #f1f1f1;
  margin-bottom: 0 !important;
  input {
    background: #f1f1f1;
  }
  input:focus {
    background: #f1f1f1;
  }
  select {
    background: #f1f1f1;
  }
  select:focus {
    background: #f1f1f1;
  }
}
.input-profile:focus {
  background: #f1f1f1;
}
.background-user {
  height: 20vh;
  width: 100%;
  background: #e19c9b;
  .icon-setting {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    background: #0075b9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
}
.color {
  margin: 0 auto;
}
.btn-submit-color {
  text-align: center;
  margin-top: 20px;
}
.content-profile {
  padding: 20px 20px;
  .body-profile {
    box-shadow: 0px 3px 6px #7681929c;
    border-radius: 20px;
    .body-title {
      padding-left: 10px;
      padding-top: 10px;
    }
    .number-point {
      text-align: center;
      font-size: 32px;
    }
    .unit-point {
      padding-right: 10px;
      text-align: right;
      padding-bottom: 10px;
    }
    .user-profile {
      text-align: center;
      padding: 20px 20px;
    }
    .survey {
      margin-left: 40px;
      div {
        button {
          margin: 0 0 1rem 10px;
        }
      }
    }
  }
}
.btn-upload-image {
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
.btn-upload-color {
  width: 70px;
  height: 30px;
  border: 5px solid #bcbcbd;
  border-radius: 5px;
}
.btn-show-all {
  text-align: center;
  button {
    margin-bottom: 10px;
  }
}

// Roller
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-cancel-text {
  color: #999;
}
.btn-cancel-text:hover {
  cursor: pointer;
}
.btn-success-custom {
  background-color: #555;
  width: 100%;
  margin: 40px auto 0;
  border: none;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  height: 50px;
  line-height: 38px;
  background-color: #1d1d1d;
  color: #fff;
  transition: background-color 0.4s ease;
  border-radius: 0px;
  margin: 40px auto 0;
  color: #fff;
}
.cus-delete-act {
  height: 268px;
}
.cus-delete-act p {
  font-size: 12px;
}

.version-pc {
  .vue-html2pdf {
    position: absolute;
    top: -10%;
    left: 50%;
    z-index: 999999;

    .layout-container {
      display: none !important;
    }

    .pdf-preview {
      position: absolute !important;
      width: 100vh !important;
      height: 80vh !important;

      @media (max-width: 500px) {
        width: 80vh !important;
      }
    }
  }

  .slick-slide {
    width: 70vw !important;

    .body-profile {
      margin: 0 2vw;
    }
    @media (max-width: 500px) {
      width: 100vw !important;
    }
  }
}

.custom-input-group {
  display: flex;
  line-height: 35px;
  .input-profile {
    width: 60px;
  }
  @media (max-width: 800px) {
    display: block;
  }
}

.detail-background-user {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  max-width: 950px;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 95% !important;
  }

  .avatar-pc {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100px;
    height: 100px;
    background: #0075b9;
    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 100%;
    }

    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .icon-setting-pc {
    width: 30px;
    height: 30px;
    margin: 20px;
  }

  .statistical-order {
    margin: auto 7rem auto 0;
  }

  .color {
    position: absolute;
    top: 0;
    right: 60px;
  }
}
.car-slide {
  .slick-track {
    display: flex;
    width: max-content !important;
  }
}
.cus-tt {
  min-width: 98px;
  padding: 20px 0px 20px 20px;
  max-width: 25%;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.cus-inp {
  padding: 20px 0px 20px 20px;
  font-size: 13px;
  max-width: 45%;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 20px;
  }
}
.cus-mg {
  margin-right: 1em;
}
</style>
