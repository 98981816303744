<template>
  <div class="card-content detail-content">
    <Tables
      :items="listPurchaseHistory"
      :fields="fieldsPurchaseHistory"
      :itemsPerPageSelect="{}"
    >
      <template v-slot:created_at="{ item }">
        <td class="test">
          <div v-if="item.created_at !== null">
            {{ moment(new Date(item.created_at)).format("YYYY年MM月DD日") }}
          </div>
        </td>
      </template>
      <template v-slot:action="{ item }">
        <td>
          <Download
            :idOrder="item.order_id"
            :contentTitle="item.content_title"
            version="sp"
          />
        </td>
        <!-- <td class="btn-cancel">
          <button
            class="btn btn-secondary"
            @click="goContentDetail(item.id)"
            style="min-width: 100px"
          >
            明細書
          </button>
        </td> -->
      </template>
    </Tables>
  </div>
</template>

<script>
import { tableFields } from "../../utils/table-fields.js";
import Tables from "../../components/common/Tables";
import { mapActions } from "vuex";
import moment from "moment";
import { Constants } from "../../utils/constants.js";
import Download from "../pages/pagePrintInvoice";

export default {
  name: "MyProfile",
  components: {
    Tables,
    Download,
  },
  props: ["listPurchaseHistory"],
  data() {
    return {
      fieldsPurchaseHistory: tableFields.PURCHASE_SUBSCRIPTION,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {},
  methods: {
    ...mapActions({
      cancelSubscriptionUser: "cancelSubscriptionUser",
    }),
    moment,
    goContentDetail(content_id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "DetailContentLibrary"
          : "DetailContentLibrary domain",
        params: {
          idContent: content_id,
          shopId: this.shop_id,
        },
      });
    },
  },
};
</script>
